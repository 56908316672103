<template>
  <div id="landing-page">
    <div class="ptrn_cir cir_ptrn_one"></div>
    <div class="ptrn_cir cir_ptrn_two"></div>
    <div class="bordr_circle"></div>
    <div class="bordr_circle_two"></div>
    <v-row
      class="pa-0 ma-0"
      style="height: 100vh; width: 100%; max-width: 100%"
      data-aos="zoom-in"
      data-aos-duration="500"
      data-aos-offset="0"
      data-aos-once="true"
    >
      <v-col
        class="d-flex align-center"
        cols="12"
        sm="6"
        style="font-size: 70px"
      >
        <div :class="[$vuetify.breakpoint.lgAndDown ? '' : 'mr-16']">
          <v-row>
            <v-col :class="[$vuetify.breakpoint.xs ? '' : 'title-col']">
              <v-row>
                <v-col class="text-center text-sm-left pa-0 upper-line-height">
                  <span class="title-text"> Score. Track. Compare. </span>
                </v-col>
              </v-row>
              <v-row
                style="font-size: 35%"
                class="py-4 text-center text-sm-left"
              >
                <span>The World's First Annuity Operating System.</span>
              </v-row>
              <v-row class="justify-center justify-sm-start">
                <v-btn
                  class="custom_glow_btn custom_btn mr-4"
                  x-large
                  outlined
                  @click="goTo('/pricing')"
                >
                  CHOOSE PLAN
                </v-btn>
                <!-- <v-btn class="custom_btn" x-large outlined @click="watchDemo()">
                  WATCH DEMO
                </v-btn> -->
                <!-- <v-btn class="custom_glow_btn custom_btn mr-4" x-large outlined @click="goTo('/marketing')">
                  GO TO LEAD CONTROL
                </v-btn> -->
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        style=""
        class="d-flex align-center"
        :class="[$vuetify.breakpoint.smAndDown ? 'mx-auto' : 'px-10']"
      >
        <v-img src="@/assets/home_images/home_graphic.png" contain />
      </v-col>
    </v-row>
    <div class="thirty-spacer"></div>
    <main
      class="site_cnct"
      data-aos="fade-up"
      data-aos-duration="700"
      data-aos-offset="0"
      data-aos-once="true"
    >
      <section class="con_sec sc_bg_bdr ylw_sec_bdr" style="">
        <div class="section-container mx-auto">
          <div class="row">
            <div class="col-sm-5 d-flex justify-center">
              <div v-if="!$vuetify.breakpoint.xs" class="img_wrap">
                <center>
                  <img
                    class="sec-img"
                    style=""
                    src="@/assets/home_images/cnct_img_1.png"
                    alt=""
                  />
                </center>
              </div>
            </div>
            <div class="col-sm-7 d-flex justify-center">
              <div class="cnct_wrap">
                <h2 class="section-title">Close More Deals, Faster</h2>
                <p class="section-text">
                  AnnuityOS is designed to help you and your clients make more
                  informed decisions when it comes to their annuities. With our
                  data-driven insights, you can help them maximize their annuity
                  options, and make adjustments accordingly.
                </p>
                <p class="section-text">
                  Whether your focus lies in annuities or in assets under
                  management, AnnuityOS massively boosts the value of every
                  client and prospect.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="bdr_shp">
          <img src="@/assets/home_images/Line_1.svg" alt="" />
        </div>
        <div class="top_shp pa-0 ma-0">
          <img src="@/assets/home_images/top_shp.svg" alt="" />
        </div>
        <div class="btm_shp">
          <img src="@/assets/home_images/btm_shp.svg" alt="" />
        </div>
        <div class="bdr_shp_btm">
          <img src="@/assets/home_images/Line_2.svg" alt="" />
        </div>
      </section>
    </main>
    <div class="forty-spacer"></div>
    <main
      class="site_cnct"
      data-aos="fade-up"
      data-aos-duration="700"
      data-aos-offset="0"
      data-aos-once="true"
    >
      <section class="con_sec">
        <div class="section-container mx-auto">
          <div class="row d-flex rev">
            <div class="col-sm-5 d-flex justify-center">
              <div v-if="!$vuetify.breakpoint.xs" class="img_wrap">
                <center>
                  <img
                    class="sec-img"
                    src="@/assets/home_images/cnct_img_2.png"
                    alt=""
                  />
                </center>
              </div>
            </div>
            <div class="col-sm-7 d-flex justify-center">
              <div class="cnct_wrap">
                <h2 class="section-title">Score</h2>
                <p class="section-text">
                  Running a third-party AnnuityScore for every contract that
                  your client or prospect owns creates clarity and helps
                  overcome prospects' natural bias to protect previous decisions
                  while helping establish your fiduciary responsibility.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <div style="height: 20vh"></div>
    <main
      class="site_cnct"
      data-aos="fade-up"
      data-aos-duration="700"
      data-aos-offset="0"
      data-aos-once="true"
    >
      <section class="con_sec">
        <div class="section-container mx-auto">
          <div class="row">
            <div class="col-sm-5 d-flex justify-center">
              <div v-if="!$vuetify.breakpoint.xs" class="img_wrap">
                <center>
                  <img
                    class="sec-img"
                    src="@/assets/home_images/cnct_img_3.png"
                    alt=""
                  />
                </center>
              </div>
            </div>
            <div class="col-sm-7 d-flex justify-center">
              <div class="cnct_wrap">
                <h2 class="section-title">Track</h2>
                <p class="section-text">
                  Updating client scores annually and reviewing their contract
                  performance and liquidity using our automated reminders makes
                  follow-up a snap. With AnnuityOS, everything is at your
                  fingertips.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <div class="forty-spacer"></div>
    <main
      class="site_cnct"
      data-aos="fade-up"
      data-aos-duration="700"
      data-aos-offset="0"
      data-aos-once="true"
    >
      <section class="con_sec sc_bg_bdr rd_sec_bdr">
        <div class="section-container mx-auto">
          <div class="row d-flex rev">
            <div class="col-sm-5 d-flex justify-center">
              <div v-if="!$vuetify.breakpoint.xs" class="img_wrap">
                <center>
                  <img
                    class="sec-img"
                    src="@/assets/home_images/cnct_img_5.png"
                    alt=""
                  />
                </center>
              </div>
            </div>
            <div class="col-sm-7 d-flex justify-center">
              <div class="cnct_wrap">
                <h2 class="section-title">Compare</h2>
                <p class="section-text">
                  If you decide a full or partial transfer is in the best
                  interest of your client, our Comparison Tool can increase the
                  transferable dollar amount with our proprietary
                  Zero-Loss-Transfer calculation. We also determine the income
                  account and death benefit ratios.
                </p>
                <!-- <p class="section-text">
                  you only need to input the new Account and Surrender values
                  plus enter any new withdrawals or deposits made during the
                  previous year.
                </p> -->
              </div>
            </div>
          </div>
        </div>
        <div class="bdr_shp">
          <img src="@/assets/home_images/Line_3.svg" alt="" />
        </div>
        <div class="top_shp">
          <img src="@/assets/home_images/top_shp.svg" alt="" />
        </div>
        <div class="btm_shp">
          <img src="@/assets/home_images/btm_shp.svg" alt="" />
        </div>
        <div class="bdr_shp_btm">
          <img src="@/assets/home_images/Line_4.svg" alt="" />
        </div>
      </section>
    </main>
    <div class="forty-spacer"></div>
    <main
      class="site_cnct"
      data-aos="fade-up"
      data-aos-duration="700"
      data-aos-offset="0"
      data-aos-once="true"
    >
      <section class="con_sec">
        <div class="section-container mx-auto">
          <div class="row">
            <div class="col-sm-5 d-flex justify-center">
              <div v-if="!$vuetify.breakpoint.xs" class="img_wrap">
                <center>
                  <img
                    class="sec-img"
                    src="@/assets/home_images/cnct_img_4.png"
                    alt=""
                  />
                </center>
              </div>
            </div>
            <div class="col-sm-7 d-flex justify-center">
              <div class="cnct_wrap">
                <h2 class="section-title">1-Click Contract Updates</h2>
                <p class="section-text">
                  Update your client's AnnuityScore and send them an update in
                  less than 30 seconds with only a few numbers from their most
                  recent statement. After each update, we store the previous
                  AnnuityScore in contract history.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <div class="forty-spacer"></div>
    <main
      class="site_cnct"
      data-aos="fade-up"
      data-aos-duration="700"
      data-aos-offset="0"
      data-aos-once="true"
    >
      <section class="con_sec">
        <div class="section-container mx-auto">
          <div class="row d-flex rev">
            <div class="col-sm-5 d-flex justify-center">
              <div v-if="!$vuetify.breakpoint.xs" class="img_wrap">
                <center>
                  <img
                    class="sec-img"
                    src="@/assets/home_images/cnct_img_9.png"
                    alt=""
                  />
                </center>
              </div>
            </div>
            <div class="col-sm-7 d-flex justify-center">
              <div class="cnct_wrap">
                <h2 class="section-title">Aggregate Your Business</h2>
                <p class="section-text">
                  By digitizing, scoring, and tracking clients and prospects in
                  AnnuityOS, you can create an asset that may be sold and
                  transferred, massively increasing the value of your business.
                </p>
                <!-- <p class="section-text">
                  You can rest assured that our calculations are always based on
                  the best annuity data available anywhere at any price.
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <div class="forty-spacer"></div>
    <main
      class="site_cnct"
      data-aos="fade-up"
      data-aos-duration="700"
      data-aos-offset="0"
      data-aos-once="true"
    >
      <section class="con_sec sc_bg_bdr grn_sec_bdr">
        <div class="section-container mx-auto">
          <div class="row">
            <div class="col-sm-5 d-flex justify-center">
              <div v-if="!$vuetify.breakpoint.xs" class="img_wrap">
                <center>
                  <img
                    class="sec-img"
                    src="@/assets/home_images/cnct_img_8.png"
                    alt=""
                  />
                </center>
              </div>
            </div>
            <div class="col-sm-7 d-flex justify-center">
              <div class="cnct_wrap">
                <h2 class="section-title">Join Our 9-Figure Club</h2>
                <p class="section-text">
                  The 9-Figure Club Award is for reaching the magic $100,000,000
                  or more in tracked premium. To make 7 figures, track 9
                  figures.
                </p>
                <!-- <p class="section-text">
                  That's why we're offering a one-click, no-questions-asked
                  money-back guarantee for your first 14 days.
                </p> -->
              </div>
            </div>
          </div>
        </div>
        <div class="bdr_shp">
          <img src="@/assets/home_images/Line_5.svg" alt="" />
        </div>
        <div class="top_shp">
          <img src="@/assets/home_images/top_shp.svg" alt="" />
        </div>
        <div class="btm_shp">
          <img src="@/assets/home_images/btm_shp.svg" alt="" />
        </div>
        <div class="bdr_shp_btm">
          <img src="@/assets/home_images/Line_6.svg" alt="" />
        </div>
      </section>
    </main>
    <div class="forty-spacer"></div>
    <main
      class="site_cnct"
      data-aos="fade-up"
      data-aos-duration="700"
      data-aos-offset="0"
      data-aos-once="true"
    >
      <section class="con_sec">
        <div class="section-container mx-auto">
          <div class="row d-flex rev">
            <div class="col-sm-5 d-flex justify-center">
              <div v-if="!$vuetify.breakpoint.xs" class="img_wrap">
                <center>
                  <img
                    class="sec-img"
                    src="@/assets/home_images/cnct_img_6.png"
                    alt=""
                  />
                </center>
              </div>
            </div>
            <div class="col-sm-7 d-flex justify-center">
              <div class="cnct_wrap">
                <h2 class="section-title">Data That You Can Trust</h2>
                <p class="section-text">
                  With AnnuityOS, your data is fully encrypted and AnnuityOS
                  software is backed by the data integrity of MorningStar.
                  Morningstar provides us with reliable fee amounts and charges
                  on over 2,600 carrier-product combinations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <div class="forty-spacer"></div>
    <main
      class="site_cnct"
      data-aos="fade-up"
      data-aos-duration="700"
      data-aos-offset="0"
      data-aos-once="true"
    >
      <section class="con_sec sc_bg_bdr ylw_sec_bdr">
        <div class="section-container mx-auto">
          <div class="row">
            <div class="col-sm-5 d-flex justify-center">
              <div v-if="!$vuetify.breakpoint.xs" class="img_wrap">
                <center>
                  <img
                    class="sec-img"
                    src="@/assets/home_images/pay_as_you_grow.png"
                    alt=""
                  />
                </center>
              </div>
            </div>
            <div class="col-sm-7 d-flex justify-center">
              <div class="cnct_wrap">
                <h2 class="section-title">Pay As You Grow</h2>
                <p class="section-text">
                  We designed AnnuityOS to be accessible for any sized business.
                  Our monthly charge is based exclusively on usage, and there
                  are no contracts, monthly base fees, or "gotchas". Our bet is
                  that you'll love AnnuityOS and want to tell your friends!
                </p>
                <a href="/pricing" class="custom_glow_btn custom_btn"
                  >Get Started</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="bdr_shp">
          <img src="@/assets/home_images/Line_1.svg" alt="" />
        </div>
        <div class="top_shp">
          <img src="@/assets/home_images/top_shp.svg" alt="" />
        </div>
        <div class="btm_shp">
          <img src="@/assets/home_images/btm_shp.svg" alt="" />
        </div>
        <div class="bdr_shp_btm" style="z-index: 1000">
          <img src="@/assets/home_images/Line_2.svg" alt="" />
        </div>
      </section>
    </main>

    <div class="forty-spacer"></div>
    <main
      class="site_cnct"
      data-aos="fade-up"
      data-aos-duration="700"
      data-aos-offset="0"
      data-aos-once="true"
    >
      <section class="con_sec">
        <div class="section-container mx-auto">
          <div class="row d-flex rev">
            <div class="col-sm-5 d-flex justify-center">
              <div v-if="!$vuetify.breakpoint.xs" class="img_wrap">
                <center>
                  <img
                    class="sec-img"
                    src="@/assets/home_images/cnct_img_7.png"
                    alt=""
                  />
                </center>
              </div>
            </div>
            <div class="col-sm-7 d-flex justify-center">
              <div class="cnct_wrap">
                <h2 class="section-title">14-Day Money Back Guarantee</h2>
                <p class="section-text">
                  We understand that AnnuityOS may not be the right fit for
                  everyone. That's why we offer a one-click, no questions asked,
                  14-day money back guarantee. You'll incur no usage charges and
                  have your full setup fee refunded.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

    <main
      class="site_cnct"
      style="background-color: transparent"
      data-aos="fade-up"
      data-aos-duration="700"
      data-aos-offset="0"
      data-aos-once="true"
    >
      <footer class="ftr_sec" style="background-color: transparent">
        <div class="section-container mx-auto">
          <div class="row d-flex justify-sm-center">
            <div class="col-sm-4">
              <div class="ftr_cncts">
                <a href="#" class="ftr_logo">
                  <img src="@/assets/home_images/logo.svg" alt="" />
                </a>
                <p>
                  Thank you for your interest in AnnuityOS. Tell your friends!
                  <br />
                  <br />
                  Best, <br />
                  Steve, Matt, and Grey <br />
                  <i>The AOS Team</i>
                </p>
              </div>
            </div>
            <div class="col-6 col-md-3 col-sm-4">
              <div class="ftr_lnks_wrap">
                <h5>Quick Links</h5>
                <ul class="ftr_lnks">
                  <li>
                    <a href="#"> Home </a>
                  </li>
                  <li>
                    <a href="/login"> Sign In </a>
                  </li>
                  <li>
                    <a href="/register"> Register </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-6 col-md-3 col-sm-4">
              <div class="ftr_lnks_wrap">
                <h5>Resources</h5>
                <ul class="ftr_lnks">
                  <li>
                    <a href="https://www.linkedin.com/in/hutchinsonsteve/">
                      LinkedIn
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCmY_TejQ6-CrNe1W2jQ6k3Q"
                    >
                      Youtube
                    </a>
                  </li>
                  <li>
                    <a href="https://www.annuityos.com/terms">
                      Terms & Conditions of Use
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="copyrgt_sec">
            <p>&copy; 2024 AnnuityOS | All Rights Reserved.</p>
            <p>
              Questions, comments, concerns? Contact
              <a href="mailto:hey@annuityos.com">hey@annuityOS.com</a> for
              support.
            </p>
          </div>
        </div>
      </footer>
    </main>

    <v-dialog
      v-model="dialog"
      hide-overlay
      style="box-shadow: none; background-color: transparent"
    >
      <v-card class="mx-auto video-player">
        <!-- <v-card-title class="text-h5"> AnnuityOS Demo </v-card-title> -->

        <v-card-text class="ma-0 pa-0">
          <VideoPlayer
            :options="videoOptions"
            :paused="videoPaused"
          ></VideoPlayer>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <h5>AnnuityOS Demo</h5>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
html {
  font-family: "Raleway" !important;
}

.section-container {
  width: 85%;
}

.thirty-spacer {
  height: 35vh;
}

.forty-spacer {
  height: 45vh;
}

.title-col {
  margin-left: 25%;
}

.sec-img {
  max-width: 450px;
  max-height: 450px;
}

.section-title {
  font-size: 65px;
  font-weight: 400;
  line-height: 110%;
}

.section-text {
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
}

.title-text {
  font-weight: 500;
}

.eighty-width {
  width: 80%;
}

.full-width {
  width: 100%;
}

#landing-page {
  font-family: "Raleway", sans-serif;
  background-color: var(--home-bkg);

  background-position: center;
  margin-top: -65px;
  padding-top: 35px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.ptrn_cir {
  position: absolute;
  width: 426px;
  height: auto;
  aspect-ratio: 1/1;
  background: #0862ba;
  opacity: 0.7;
  filter: blur(305px);
  top: -43px;
}

.cir_ptrn_one {
  left: 0px;
  object-fit: cover;
}

.cir_ptrn_two {
  right: 0px;
  object-fit: cover;
}

.bordr_circle {
  position: absolute;
  width: 1257px;
  height: 1257px;
  left: -641px;
  top: -542px;
  border: 1px solid rgba(12, 103, 64, 0.1);
  border-radius: 50%;
  object-fit: cover;
}

.bordr_circle_two {
  position: absolute;
  width: 824px;
  height: 824px;
  left: -342px;
  top: -367px;
  border: 1px solid rgba(12, 103, 64, 0.1);
  border-radius: 50%;
  object-fit: cover;
}

.custom_glow_btn {
  box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(42, 208, 164, 0.5);
  -webkit-box-shadow: inset 0px 0px 7.1px -4px var(--green),
    -5px -3px 1px -8px var(--green);
  -moz-box-shadow: inset 0px 0px 7.1px -4px var(--green),
    -5px -3px 1px -8px var(--green);
  box-shadow: inset 0px 0px 7.1px -4px var(--green),
    -5px -3px 1px -8px var(--green);
}

.custom_btn {
  border-radius: 4px;
  position: relative;
  font-size: 16px;
  text-transform: uppercase;
  padding: 13px 37px;
  color: var(--white);
  border: 1px solid rgb(213 213 213 / 20%);
  display: inline-block;
}

.custom_btn:hover {
  color: white;
}

.custom_btn::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: var(--green);
  opacity: 0;
  filter: blur(7px);
  transition: 400ms all ease-in-out;
}

.custom_btn:hover:before {
  opacity: 0.25;
}

.site_cnct {
  position: relative;
  z-index: 1;
  /* background: var(--dark_green); */
}

.site_cnct .con_sec.sc_bg_bdr:first-child {
  margin-top: 0;
}

.site_cnct .con_sec.sc_bg_bdr:last-child {
  margin-bottom: 0;
}

.con_sec.sc_bg_bdr {
  margin: 120px 0;
  padding: 0;
  /* background: linear-gradient(180deg, #06180E 6.05%, rgba(6, 24, 14, 0) 60.59%); */
  background-color: var(--dark_green);
}

.con_sec .bdr_shp {
  position: absolute;
  bottom: 100%;
  width: 100%;
  margin-bottom: 25px;
  z-index: 1000;
}

.con_sec .bdr_shp img {
  width: 100%;
  z-index: 1000;
}

.con_sec .bdr_shp_btm {
  position: absolute;
  width: 100%;
  top: 100%;
  margin-top: 25px;
  z-index: 1000;
}

.con_sec .bdr_shp_btm img {
  width: 100%;
}

.con_sec .top_shp {
  position: absolute;
  bottom: 98%;
  width: 100%;
}

.con_sec .top_shp img {
  width: 100%;
}

.con_sec .btm_shp {
  position: absolute;
  top: 100%;
  width: 100%;
}

.con_sec .btm_shp img {
  width: 100%;
}

.con_sec .row {
  align-items: center;
}

main .con_sec .rev.row {
  flex-direction: row-reverse;
}

.con_sec .img_wrap {
  position: relative;
  z-index: 1;
  max-width: 350px;
  max-height: 350px;
  filter: drop-shadow(0px 0px 100px rgb(24 118 210 / 60%));
}

.con_sec.sc_bg_bdr .img_wrap {
  filter: drop-shadow(0px 0px 100px rgb(26 120 94 / 40%));
}

main .con_sec:nth-child(even) .row .img_wrap {
  margin-left: auto;
}

.con_sec .cnct_wrap h2 {
  position: relative;
  max-width: 500px;
  padding-bottom: 20px;
  margin-bottom: 25px;
}

.con_sec .cnct_wrap h2.section-title::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 120px;
  height: 2px;
  background-color: var(--green);
}

.con_sec .cnct_wrap h2.ylw_bdr::after {
  background-color: var(--yellow);
}

.con_sec .cnct_wrap p:not(:first-child) {
  margin-top: 20px;
}

.con_sec .cnct_wrap a {
  margin-top: 20px;
}

main .con_sec:nth-child(even) .row {
  flex-direction: row-reverse;
}

main .con_sec:nth-child(even) .row .img_wrap {
  margin-left: auto;
}

.ftr_sec {
  padding: 450px 0 620px;
  background-color: var(--dark_green);
  background-image: url(@/assets/home_images/footer-bg.png);
  background-position: bottom;
  background-size: 100%;
  background-repeat: no-repeat;
}

.ftr_sec .ftr_lnks_wrap {
  max-width: max-content;
}

.ftr_sec .ftr_cncts p {
  font-size: 15px;
  line-height: 28px;
  color: var(--ash);
  padding: 30px 0 55px;
}

.ftr_sec .ftr_cncts ul {
  display: flex;
  align-items: center;
  column-gap: 25px;
}

.ftr_sec .ftr_cncts ul li a {
  color: var(--white);
  font-size: 25px;
}

.ftr_sec .ftr_cncts ul li a:hover {
  color: var(--yellow);
}

.ftr_sec .ftr_lnks_wrap h5 {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 25px;
  color: var(--white);
  font-weight: 500;
}

.ftr_sec .ftr_lnks_wrap ul li a {
  color: var(--ash);
  margin-bottom: 20px;
}

.ftr_sec .ftr_lnks_wrap ul li a:hover {
  color: var(--yellow);
}

.ftr_sec .copyrgt_sec {
  text-align: center;
  margin-top: 80px;
  color: var(--ash);
}

@media (min-width: 1264px) {
  .video-player {
    width: 60vw;
  }

  .title-text {
    font-size: 100%;
  }

  .upper-line-height {
    line-height: 120%;
  }

  .sec-img {
    max-width: 100%;
  }
}

@media (min-width: 960px) and (max-width: 1264px) {
  .video-player {
    width: 60vw;
  }

  .title-text {
    font-size: 85%;
  }

  .title-col {
    margin-left: 20%;
  }

  .upper-line-height {
    line-height: 115%;
  }

  .sec-img {
    max-width: 90%;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .video-player {
    width: 70vw;
  }

  .title-text {
    font-size: 70%;
  }

  .title-col {
    margin-left: 15%;
  }

  .upper-line-height {
    line-height: 100%;
  }

  .sec-img {
    max-width: 80%;
  }
}

@media (max-width: 600px) {
  .video-player {
    width: 85vw;
  }

  .title-text {
    font-size: 55%;
  }

  .section-title {
    font-size: 45px;
    font-weight: 400;
  }

  .upper-line-height {
    line-height: 70%;
  }

  .sec-img {
    max-width: 100%;
  }

  .thirty-spacer {
    height: 15vh;
  }

  .forty-spacer {
    height: 20vh;
  }

  .cir_ptrn_one {
    width: 100%;
    object-fit: cover;
  }

  .cir_ptrn_two {
    width: 100%;
    object-fit: cover;
  }

  .bordr_circle {
    width: 100%;
    object-fit: cover;
  }

  .bordr_circle_two {
    width: 100%;
    object-fit: cover;
  }
}
</style>

<script lang="ts">
import AOS from "aos";
import "aos/dist/aos.css";
import Vue from "vue";
import VideoPlayer from "@/components/VideoPlayer.vue";

export default Vue.extend({
  mounted() {
    AOS.init({
      disable: "mobile",
    });
  },
  components: {
    VideoPlayer,
  },
  data() {
    return {
      dialog: false,
      videoPaused: true,
      videoOptions: {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: "https://videodelivery.net/4ec51b4e7241a6b5ec39ca2a6e3c98c0/manifest/video.m3u8?clientBandwidthHint=1000",
            type: "application/x-mpegURL",
          },
        ],
      },
    };
  },

  watch: {
    dialog(newVal: boolean, oldVal: boolean) {
      this.videoPaused = !newVal;
    },
  },
  methods: {
    // async getHLSStream(id: string) {
    //   const url = `https://videodelivery.net/${id}`;
    //   const res = await fetch(`${url}/manifest/video.m3u8`);

    //   const streamText = await res.text();

    //   const parts = /#EXT-X-STREAM-INF:RESOLUTION=(.*)\n(.*)/gm.exec(
    //     streamText
    //   );
    //   //correct error of parts being possibly null
    //   if (parts === null) {
    //     return "";
    //   }
    //   return `${url}/manifest/${parts[2]}`;
    // },

    loggie() {
      console.log(this.$store.state.cart.been_to_pricing);
    },
    watchDemo() {
      this.dialog = true;
    },
    goTo(url: string) {
      this.$router.push(url);
    },
  },
});
</script>
