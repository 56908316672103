<template>
  <v-app :style="{ background: bg_theme }">
    <v-navigation-drawer v-model="drawer" fixed temporary dark right clipped>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="text--accent-4"
          style="font-size: xx-large"
        >
          <v-list-item>
            <v-img
              alt="AnnuityOS"
              class="mr-2 mt-5 mb-5"
              contain
              src="@/assets/annuityos_blue.png"
              transition="scale-transition"
              width="120"
              @click="scrollToTop"
            />
          </v-list-item>

          <v-list-item
            link
            v-for="(tab, index) in c_state_nav[c_state].filter(
              (item) => !item.menu_item && cust_item_filter(item.text)
            )"
            :key="index"
            :to="tab.custom_action ? '' : tab.to"
            @click.stop="tab.custom_action ? tab.custom_action() : null"
          >
            <v-list-item-icon>
              <v-icon>{{ tab.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ tab.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div v-if="c_state === 'logged_in_admin'">
            <v-divider class="mt-2 mb-2"></v-divider>
            <h3>Admin</h3>
            <v-list-item
              link
              v-for="(tab, index) in c_state_nav.logged_in_admin.filter(
                (item) => item.menu_item
              )"
              :key="index"
              :to="tab.to"
            >
              <v-list-item-icon>
                <v-icon>{{ tab.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ tab.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div
            v-if="
              c_state === 'logged_in_marketer' || c_state === 'logged_in_admin'
            "
          >
            <v-divider class="mt-2 mb-2"></v-divider>
            <h3>Marketer</h3>
            <v-list-item
              link
              v-for="(tab, index) in c_state_nav.logged_in_marketer.filter(
                (item) => item.menu_item
              )"
              :key="index"
              :to="tab.to"
            >
              <v-list-item-icon>
                <v-icon>{{ tab.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ tab.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      class="bartransition py-0"
      app
      dark
      elevation="0"
      :style="{ 'background-color': bg }"
    >
      <div class="d-flex align-center" style="height: 100%">
        <router-link to="/">
          <v-img
            alt="AnnuityOS"
            class="mr-2"
            contain
            src="/assets/annuityos_blue.png"
            transition="scale-transition"
            width="220"
            height="100%"
            @click="scrollToTop"
          />
        </router-link>
      </div>
      <v-spacer></v-spacer>

      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.xs"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-tabs right optional v-else v-model="tab">
        <v-tab
          class="nav-item"
          :id="tab.to.substring(1)"
          v-for="(tab, index) in c_state_nav[c_state].filter(
            (item) => !item.menu_item && cust_item_filter(item.text)
          )"
          :key="index"
          :to="tab.custom_action ? '' : tab.to"
          @click.stop="tab.custom_action ? tab.custom_action() : null"
        >
          {{ tab.text }}
        </v-tab>
        <v-menu
          bottom
          offset-y
          open-on-hover
          v-if="c_state === 'logged_in_admin'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              class="nav-item align-self-center mr-4"
              style="font-weight: 300"
              v-bind="attrs"
              v-on="on"
            >
              Admin
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              link
              v-for="(tab, index) in c_state_nav.logged_in_admin.filter(
                (item) => item.menu_item
              )"
              :key="index"
              :to="tab.to"
            >
              <v-list-item-content>
                <v-list-item-title> {{ tab.text }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu
          bottom
          offset-y
          open-on-hover
          v-if="
            c_state === 'logged_in_marketer' || c_state === 'logged_in_admin'
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              class="nav-item align-self-center mr-4"
              style="font-weight: 300"
              v-bind="attrs"
              v-on="on"
            >
              Marketer
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              link
              v-for="(tab, index) in c_state_nav.logged_in_marketer.filter(
                (item) => item.menu_item
              )"
              :key="index"
              :to="tab.to"
            >
              <v-list-item-content>
                <v-list-item-title> {{ tab.text }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-tabs>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-snackbar
      v-model="$store.state.snackbar.show"
      :timeout="$store.state.snackbar.timeout"
    >
      {{ $store.state.snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="$store.state.snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<style scoped>
/* change font size of item in the nav-item horizontal menu to 0.5em */
.nav-item {
  font-size: 0.95em;
}
/* do the same for menu nav items */
::v-deep .nav-item .v-btn__content {
  font-size: 0.85em;
}
</style>

<script lang="ts">
import Vue from "vue";
import { auth } from "@/firebaseConfig";
import { signOut } from "firebase/auth";
export default Vue.extend({
  name: "App",
  created() {},
  mounted() {
    this.resizeOnUserStateChange();
    this.scrollToTop();

    if (document.location.pathname != "/") {
      this.bg = this.bg_theme;
    } else {
      window.onscroll = () => {
        this.changeColor();
      };
    }
  },
  computed: {
    //create a function that returns the login state
    c_state() {
      if (this.$store.state.user.is_login) {
        if (this.$store.state.user.acl === 10) {
          return "logged_in_admin";
        } else if (this.$store.state.user.acl >= 5) {
          return "logged_in_marketer";
        } else if (
          this.$store.state.user.acl >= 1 ||
          this.$store.state.user.recurlyStatus === "active"
        ) {
          return "logged_in_paid";
        } else {
          return "logged_in_not_paid";
        }
      } else {
        return "not_logged_in";
      }
    },
  },
  methods: {
    cust_item_filter(item: string): boolean {
      // if super admin, show all
      if (this.$store.state.user.is_login && this.$store.state.user.acl == 10) {
        return true;
      }

      if (item === "Zero-Loss Transfer") {
        if (this.$store.state.user.is_login) {
          if (this.$store.state.user.imo == "eca") {
            return true;
          }
        }
        return false;
      }

      return true;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    changeColor() {
      if (document.location.pathname != "/") {
        this.bg = this.bg_theme;
      } else {
        if (
          // document.body.scrollTop > 100 ||
          document.documentElement.scrollTop > 120
          // $(window).scrollTop() !== 0
        ) {
          this.bg = this.bg_theme; //bg_theme
        } else {
          this.bg = "transparent";
        }
      }
    },
    async resizeOnUserStateChange() {
      await this.$store.state.user_loading;
      window.dispatchEvent(new Event("resize"));
    },
    signout() {
      signOut(auth)
        .then(() => {
          window.location.href = "/";
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  data() {
    return {
      bg_theme: this.$vuetify.theme.currentTheme.background as string,
      bg: "transparent",
      tab: null,
      drawer: false,
      group: null,
      c_state_nav: {
        logged_in_admin: [
          {
            text: "Zero-Loss Transfer",
            icon: "mdi-cog",
            to: "/zlt",
          },
          {
            text: "Dashboard",
            icon: "mdi-calculator",
            to: "/dashboard",
          },

          {
            text: "Account",
            icon: "mdi-account",
            to: "/account",
          },
          {
            text: "Sign Out",
            icon: "mdi-logout",
            to: "/",
            custom_action: (this as any).signout,
          },
          {
            text: "Advisors",
            icon: "mdi-account-group",
            to: "/admin/advisors",
            menu_item: true,
          },
          {
            text: "Annuities",
            icon: "mdi-text-account",
            to: "/admin/annuities",
            menu_item: true,
          },
          {
            text: "Analytics",
            icon: "mdi-graph-outline",
            to: "/admin/analytics",
            menu_item: true,
          },
          {
            text: "ZLT",
            icon: "mdi-cog",
            to: "/admin/zlt",
            menu_item: true,
          },
          {
            text: "DTCC Sync",
            icon: "mdi-cloud",
            to: "/admin/sync",
            menu_item: true,
          },
          // {
          //   text: "Enterprise Tool",
          //   icon: "mdi-sign-caution",
          //   to: "/admin/enterprise",
          //   menu_item: true,
          // },
        ] as any[],
        logged_in_marketer: [
          {
            text: "Zero-Loss Transfer",
            icon: "mdi-cog",
            to: "/zlt",
          },
          {
            text: "Dashboard",
            icon: "mdi-calculator",
            to: "/dashboard",
          },
          {
            text: "Account",
            icon: "mdi-account",
            to: "/account",
          },
          {
            text: "Sign Out",
            icon: "mdi-logout",
            to: "/",
            custom_action: (this as any).signout,
          },
          {
            text: "Enterprise",
            icon: "mdi-account-group",
            to: "/enterprise",
            menu_item: true,
          },
          {
            text: "Advisors",
            icon: "mdi-account-group",
            to: "/marketer/advisors",
            menu_item: true,
          },
          {
            text: "Annuities",
            icon: "mdi-text-account",
            to: "/marketer/annuities",
            menu_item: true,
          },
        ] as any[],
        logged_in_paid: [
          {
            text: "Dashboard",
            icon: "mdi-calculator",
            to: "/dashboard",
          },
          {
            text: "Account",
            icon: "mdi-account",
            to: "/account",
          },
          {
            text: "Sign Out",
            icon: "mdi-logout",
            to: "/",
            custom_action: (this as any).signout,
          },
        ] as any[],
        logged_in_not_paid: [
          {
            text: "Home",
            icon: "mdi-home",
            to: "/",
          },
          {
            text: "Pricing",
            icon: "mdi-calculator",
            to: "/pricing",
          },
          {
            text: "Account",
            icon: "mdi-account",
            to: "/account",
          },
          {
            text: "Sign Out",
            icon: "mdi-logout",
            to: "/",
            custom_action: (this as any).signout,
          },
        ] as any[],
        not_logged_in: [
          {
            text: "Home",
            icon: "mdi-home",
            to: "/",
          },
          {
            text: "Pricing",
            icon: "mdi-storefront",
            to: "/pricing",
          },
          {
            text: "Sign In",
            icon: "mdi-login",
            to: "/login",
          },
          {
            text: "Sign Up",
            icon: "mdi-account-plus-outline",
            to: "/register",
          },
        ] as any[],
      },
    };
  },
});
</script>

<style scoped>
.bartransition {
  transition: all 400ms ease-in-out !important;
}
</style>
